import { h, render, Fragment } from "preact";
import Steps from "js/components/design/home/Steps.js";

export default function Borrow() {
  return (
    <div className="bg-[#fcf6dc] overflow-hidden font-faust">
      <div className="relative max-w-7xl mx-auto py-20 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col justify-center items-center text-center">
          <h2 className="text-4xl font-semibold tracking-wider text-[#381b13]">
            Getting the Funds You Need, Made Simple and Secure
          </h2>
          <p className="mt-5 text-lg text-[#381b13]">From the comfort of your <span className="text-[#dd612e]">home</span> or on the <span className="text-[#dd612e]">go</span>, Lucent Cash protects your information while providing access to the funds you need.</p>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    src="/public/src/img/divfamily_5.png"
                    alt="Loan Application"
                    data-aos="fade-in" data-aos-duration="1000" data-aos-easing="linear" data-aos-once="true"
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="flex justify-center items-center mt-8 lg:mt-0">
            <Steps />
          </div>
        </div>
      </div>
    </div>
  );
}
