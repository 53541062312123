import { h, render, Fragment } from 'preact';
import { Disclosure } from '@headlessui/react';
import { useEffect } from 'preact/hooks';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import useAuth from 'js/components/User/useAuth.js';
import logo from 'img/Lucent Cash Logo.png';

const navigation = [
    { name: 'Rates & Terms', href: '/rates-and-terms' },
    { name: 'FAQ', href: '/faq' },
    { name: 'Contact', href: '/contact-us' },
];

const navigation_2 = [
    { name: 'Rates & Terms', href: '/rates-and-terms' },
    { name: 'FAQ', href: '/faq' },
    { name: 'Contact', href: '/contact-us' },
    { name: 'My Account', href: '/account' },
];

export default function Header() {
    const auth = useAuth();

    useEffect(() => {
        if (!auth.loading && auth.user) {
            try {
                window.dataLayer.push({
                    IdentityInfo: auth.user.id,
                    event: 'zohoPageSenseEvent',
                });
            } catch (e) {
                console.error(e);
            }
        }
    }, [auth]);

    return (
        <Disclosure as="nav" className="bg-[#fbd8a0] sm:font-inter py-4">
            {({ open }) => (
                <Fragment>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-[#dd612e] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    {open ? (
                                        <XIcon
                                            className="block h-8 w-8"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <MenuIcon
                                            className="block h-8 w-8"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex flex-1 items-center justify-center md:justify-start">
                                <div className="flex flex-shrink-0 items-center">
                                    <a href="/">
                                        <img
                                            className="h-[4.5rem] w-auto"
                                            src={logo}
                                            alt="Lucent Cash Logo"
                                        />
                                    </a>
                                </div>
                                <div className="hidden md:ml-6 md:block">
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className="text-[#381b13] hover:bg-[#dd612e] hover:text-white rounded-md px-3 py-2 text-base font-medium"
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 hidden md:inline-block sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                {(auth.loading || !auth.user) && (
                                    <Fragment>
                                        <div className="hidden md:flex items-center justify-end md:flex-1">
                                            <a
                                                href="/login/"
                                                className="font-inter text-base font-medium text-[#381b13] hover:bg-[#dd612e] hover:text-white rounded-md px-3 py-2"
                                            >
                                                My Account
                                            </a>
                                        </div>
                                    </Fragment>
                                )}
                                {!auth.loading && auth.user && (
                                    <Fragment>
                                        <a
                                            href="/account/"
                                            className="font-inter text-base font-medium text-[#381b13] mr-2 hover:bg-[#dd612e] hover:text-white rounded-md px-3 py-2"
                                        >
                                            My Account
                                        </a>
                                        |
                                        <a
                                            onClick={() => {
                                                auth.sign_out(() => {
                                                    window.location = '/';
                                                });
                                            }}
                                            className="font-inter text-base font-medium text-[#381b13] ml-2 hover:bg-[#dd612e] hover:text-white rounded-md px-3 py-2 cursor-pointer"
                                        >
                                            Logout
                                        </a>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="md:hidden">
                        {(auth.loading || !auth.user) && (
                            <div className="space-y-1 px-2 pb-3 pt-2">
                                {navigation_2.map((item) => (
                                    <Disclosure.Button
                                        key={item.name}
                                        as="a"
                                        href={item.href}
                                        className="bg-[#381b13] text-white hover:bg-[#dd612e] block rounded-md px-3 py-2 text-base font-medium"
                                    >
                                        {item.name}
                                    </Disclosure.Button>
                                ))}
                            </div>
                        )}
                        {!auth.loading && auth.user && (
                            <div className="space-y-1 px-2 pb-3 pt-2">
                                {navigation_2.map((item) => (
                                    <Disclosure.Button
                                        key={item.name}
                                        as="a"
                                        href={item.href}
                                        className="bg-[#381b13] text-white hover:bg-[#dd612e] block rounded-md px-3 py-2 text-base font-medium"
                                    >
                                        {item.name}
                                    </Disclosure.Button>
                                ))}
                                <a
                                    onClick={() => {
                                        auth.sign_out(() => {
                                            window.location = '/';
                                        });
                                    }}
                                    className="bg-[#3eaf5b] text-white hover:bg-[#dd612e] block rounded-md px-3 py-2 text-base font-medium cursor-pointer"
                                >
                                    Logout
                                </a>
                            </div>
                        )}
                    </Disclosure.Panel>
                </Fragment>
            )}
        </Disclosure>
    );
}
